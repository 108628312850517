:root {
  --primary-color: #000000;
  --primary-color-alpha: #005fff1a;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
  margin: 0;
  width: 100%;
}

.channel-list__buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* Updated */
  margin-left: 20px;
}


.channel-list__button {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgb(247 247 247);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  /* Added */
  overflow: hidden;
  /* Added */
  text-overflow: ellipsis;
  /* Added */
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  width: 195px;
}

.channel-list__button__theme {
  display: inline-block;
  padding: 10px 30px;
  background-color: rgb(247 247 247);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  /* Added */
  overflow: hidden;
  /* Added */
  text-overflow: ellipsis;
  /* Added */
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  width: 195px;
}


.channel-list__button:focus,

.channel-list__button.active {
  background-color: rgba(232, 232, 232, 255);
  outline: none;
  /*box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);*/
  /*transform: translateY(1px);*/
}


.channel-list__button:hover {
  background-color: rgba(232, 232, 232, 255);
}

.channel-list__button:focus {
  outline: none;
}

.channel-preview__item {
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-info {
  flex-grow: 1;
}

.name-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-weight: bold;
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 19px;
}

.time-ago {
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
  margin-right: 10px;
}

.time-ago-other {
  color: red;
  /* Change this to your desired red color */
}


.last-message {
  flex: 1;
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 19px;
  margin-right: 19px;
  margin-top: -9px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.last-message::after {
  content: "";
}

.channel-list__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: auto;
}

.channel-list__sidebar {
  flex: 1;
}

.channel-list__sidebar>* {
  margin-bottom: 10px;
}

.channel-list__content>*:not(.channel-list__sidebar) {
  flex: 1;
  margin-left: 10px;
}

.str-chat.messaging,
.str-chat.commerce {
  background-color: white;
}

.channel-list__messages {
  opacity: 1;
  width: 350px;
  overflow-y: auto;
}

.message-type-wrapper {
  width: 250px;
}

.filter-categories__header {
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 20px;
  margin-top: 14px;
}

.filter-categories {
  opacity: 1;
  background-color: rgba(247, 247, 247, 1);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.str-chat__channel-list-empty-v1 {
  margin-left: 15px;
}

.toggle-number {
  display: flex;
  align-items: center;
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue-Thin";
  font-size: 24px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.toggle-number__button {
  border: none;
  background: transparent;
}

.toggle-number__text {
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue-Thin";
  font-size: 16px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.auth__form-title {
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}

.welcome-message {
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 16px;
  margin-right: 16px;
  border-top: 1px solid rgba(232, 232, 232, 1);
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.logo-image {
  width: 200px;
  max-height: auto;
}

.campaign-form {
  width: 100%;
  height: 100%;
}

.auth__form-mobile-message {
  
  font-size: 30px;
  font-family: "HelveticaNeue-Light";
  text-align: center;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center'
}

.unread-count {
  opacity: 1;
  color: white;
  font-family: "HelveticaNeue-Light";
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  background-color: red;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: -19px;
  padding-right: 4px;
  padding-left: 4px;
}

.logout-button {
  cursor: pointer;
  margin-top: auto;
  /* This pushes the logout button to the bottom of the filter-categories */
  margin-bottom: 15px;
  padding: 10px 20px;
  background-color: #E8E8E8;
  border: none;
  border-radius: 5px;
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  width: 160px;
  transition: 0.3s ease;
}

.logout-button:hover {
  background-color: rgba(228, 53, 53, 0.74);
}

.str-chat__avatar-fallback {
  background-color: grey;
}

.preview-delete {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.delete-icon {
  width: 15px;
  height: 15px;
}

.delete-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 29px;
  background-color: grey;
  margin-top: -9px;
  margin-right: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(232, 232, 232, 1);
  transition: 0.3s ease;
  cursor: pointer;
}

.delete-background:hover {
  background-color: rgba(228, 53, 53, 0.74);
}

.star-icon {
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
}

.star-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
  margin-right: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(232, 232, 232, 1);
  transition: 0.3s ease;
  cursor: pointer;
}

.star-background:hover {
  background-color: rgba(255, 193, 9, 100);
}

.star-background-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
  margin-right: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 193, 9, 100);
  transition: 0.3s ease;
  cursor: pointer;
}

.bell-icon {
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
}

.bell-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
  margin-right: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(232, 232, 232, 1);
  transition: 0.3s ease;
  cursor: pointer;
}

.bell-background-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
  margin-right: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: #F14232;
  transition: 0.3s ease;
  cursor: pointer;
}

.bell-background:hover {
  background-color: #F14232;
}

.reservation-icon {
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
}



/* Base button styling */
.styled-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}


/* Button hover effect */
.styled-button:hover {
  background-color: #45a049;
}

/* Clear button specific styling */

/* CSS */
.button-6 {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.button-container {
  display: flex;
  justify-content: space-between;  /* Spread the buttons to the edges */
  align-items: center;  /* Align the buttons vertically */
  padding: 0px;
  padding-left: 49px;
  padding-right: 55px;
  width: 100%;  /* Make sure the container spans the full width */
}

.set-text-button {
  background-color: #E0EFFF;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  border: none;
  border-radius: 20px;
  border-bottom-right-radius: 1px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
}

.clear-button {
  background-color: #ffe0e0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
}

.set-text-button:hover {
  transform: translateY(-1px);
}

.set-text-button:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.clear-button:hover {
  transform: translateY(-1px);
}

.clear-button:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.italic {
  font-style: italic;
}



.reservation-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  min-width: 29px;
  height: 29px;
  min-height: 29px;
  margin-right: 20px;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(232, 232, 232, 1);
  transition: 0.3s ease;
  cursor: pointer;
}

.tooltip {
  width: auto;
  background-color: #E8E8E8;
  color: #4E4E4E;
  text-align: center;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  bottom: 80%;
  content: " ";
  position: absolute;
  font-size: 12px;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -3px; /* Half of the arrow width */
  border-width: 3px;
  border-style: solid;
  border-color: #E8E8E8 transparent transparent transparent;
}


/* Other inherited CSS Below */

.auth__form-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.auth__form-content {
  margin-top: 71px;
  margin-left: 291px;
  margin-right: 291px;
  display: flex;
  flex-direction: column;
}

/*
  .auth__form-container_fields {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    background: #bedae8;
  }
  */

.auth__form-container_image {
  flex: 3;
  display: flex;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.05);
}

.auth__form-container_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth__form-container_fields-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  border-radius: 5px;
  transition: 0.8s ease;
  background: #fff;
}

.auth__form-container_fields-content p {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #05245a;
  font-weight: 900;
}

.auth__form-container_fields-content_input {
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  margin-bottom: 36px;
}

.auth__form-container_fields-content_input label {
  margin-bottom: 0.45rem;
  color: rgb(61, 79, 88);
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.7px;
  line-height: 1.3;
}

.auth__form-container_fields-content_input input {
  padding: 0.6rem 0.5rem;
  border: none;
  border-radius: 4px;
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  outline: none;
  transition: all 150ms ease-in-out 0s;
  width: 100%;
  background: #fff;

}

.auth__form-container_fields-content_input input::placeholder {
  color: #b1b1b1;
  font-weight: unset;
  font-family: Arial, Helvetica, sans-serif;
}

.auth__form-container_fields-content_input input:hover {
  border-color: #dcdddd;
}

/*
  .auth__form-container_fields-content_input input:focus,
  */
/*
  .auth__form-container_fields-content_input input:active {
    box-shadow: 0px 0px 0px 1.5px #005fff;
    border-color: #005fff;
  }
  */

.auth__form-container_fields-content_input-password {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.auth__form-container_fields-content_button {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.auth__form-container_fields-content_button button {
  border-radius: 4px;
  background: #A9DBF2;
  border: none;
  color: #fff;
  opacity: 1;
  color: rgba(78, 78, 78, 1);
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  padding: 0.7rem 1.2rem;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
  width: 100%;


}

.auth__form-container_fields-content_button button:hover {
  background: #21ce29;
}

.auth__form-container_fields-account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.auth__form-container_fields-account p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.auth__form-container_fields-account span {
  color: #05245a;
  cursor: pointer;
  font-weight: 700;
}

/*
  @media screen and (max-width: 800px) {
    .auth__form-container {
      width: 100%;
    }
  
    .auth__form-container_fields {
      justify-content: flex-start;
    }
  
    .auth__form-container_image {
      height: 100px;
      flex: none;
      box-shadow: none;
    }
  }
  */
/*
  @media screen and (max-width: 375px) {
    .auth__form-container_fields {
      padding: 2rem 0.5rem;
    }
  
    .auth__form-container_fields-content_input input {
      width: 95%;
    }
  
    .auth__form-container_fields-content_input-password {
      right: 3%;
    }
  }
  */


.app__wrapper {
  display: flex;
  flex: 1;
  /* height: 800px; */
  height: 100vh;
  width: 100%;
  /* border-radius: 16px; */
  /*box-shadow: rgba(0, 0, 0, 0.33) 0px 1px 4px 0px;*/
}

.page__wrapper {
  display: flex;
  flex: 1;
  height: 100vh;
  /* border-radius: 16px; */
  box-shadow: rgba(0, 0, 0, 0.33) 0px 1px 4px 0px;
  width: 100%;
  background-color: #F0F0F0;
}

.sections__wrapper {
  width: 100%;
  height: 100%;
}

.banner {
  width: 93px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Add this line to create space between top and bottom */
  height: 98%; /* Add this line to make sure the banner takes the full height */
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 0;
}

.top-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: auto; /* Adjust margin as needed */
}

.bottom-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;  /* Adjust margin as needed */
}

.banner_button {
  padding: 10px 10px;
  margin-bottom: 5px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333333;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  /* Center child elements horizontally */
  flex-direction: column;
  /* Stack child elements vertically */
  text-align: center;
  /* Center text within each child element */
  align-items: center;
  width: 80px;
}

.banner_button.active {
  background-color: rgba(200, 200, 200, 255);
  /* Add any other styling for the active state */
}

.banner_button_logout {
  padding: 10px 10px;
  margin-bottom: 5px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333333;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  /* Center child elements horizontally */
  flex-direction: column;
  /* Stack child elements vertically */
  text-align: center;
  /* Center text within each child element */
  align-items: center;
  width: 80px;
}


.banner_button_label {
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 5px;
}

.banner_button_label_logout {
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  width: 50px;
  border-radius: 6px;
  padding-bottom: 1px;
  margin: 5px;
  transition: 0.3s ease;
}

.banner_button:hover {
  background-color: rgba(200, 200, 200, 255);
  color: #ffffff;

  /*transform: translateY(-2px);*/
  /*transform: scale(1.05);*/
}

.banner_button_logout:hover .banner_button_label_logout {
  /* Your styles for .banner_button_label_logout on hover go here */
  /* For example: */
  color: white; /* Change text color to red */
  background-color: rgba(228, 53, 53, 0.74);  /* Change font size */
}

.banner button:focus {
  outline: none;
}

.referral-banner-text {
  
   background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
   background-size: 300%;
   background-position-x: 100%;
   animation: shimmer 3s infinite linear;

}

@keyframes shimmer {
  to {
     background-position-x: 0%
  }
}

.reports-container {
  display: grid;
  place-items: center;
  /* Center horizontally and vertically */
  grid-template-columns: 1fr;
  /* Single column */
  gap: 20px;
  /* Adjust spacing */
  padding: 20px;
  background-color: #F7F7F7;
  height: 95%;
  /* Add padding as needed */
}

.iframe-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns */
  gap: 20px;
  /* Adjust spacing between iframes */
  max-width: 1200px;
  /* Adjust maximum width of iframes */
  width: 100%;
  /* Ensure full width within the container */
  justify-content: space-evenly;
}

.full-width-iframe {
  width: 100%;
  height: 100%;
  /*max-width: 1200px;*/
  /* Adjust maximum width */
}

.first-report-view {
  width: 100%;
  height: 100%;
  /*max-width: 1200px;*/
  /* Adjust maximum width */
}

.iframe {
  width: 100%;
  height: 300px;
  /* Adjust height as needed */
  border: none;
  /* Other styles */
}

.warning-message-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Set the container to cover the full viewport height */
  background-color: #3055B2;
}

.warning-message {
  position: relative;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  /* Adjust font size as needed */
  color: white;
  /* Text color */
  text-align: center;
  font-family: 'Montserrat', sans-serif;

  /* Other text styles */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.profile-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.profile-details {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.profile-item {
  margin-bottom: 12px;
}

.item-label {
  font-weight: bold;
  margin-right: 8px;
  color: #555;
}

.item-value {
  color: #777;
}

h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #222;
}

.referral-section {
  /* Add styles for the main referral section */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  /* Make the referral section take up the full height of the viewport */
  background-color: #ffffff;
  /* Set a background color for the entire section */
}

.referral-main-title {
  /* Add styles for the referral title */
  background-color: #ffffff;
  color: black;
  padding: 15px;
  border-radius: 10px;
  width: 80%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border: 10px solid #f0f0f0;
  margin-bottom: 20px;
}

.referral-title-text {
  
  color: rgb(150, 146, 146);
  padding: 0px;
  font-family: "HelveticaNeue-Bold";
  margin: 0;
}

.code {
  /* Add styles for the coupon code */
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: rgb(112, 108, 108);
  font-family: "HelveticaNeue-Bold";
}

.code a {
  color: rgb(112, 108, 108);; /* Set your desired color */
  text-decoration: none; /* Remove underline */
}

/* Additional styles if needed */
.code a:hover {
  text-decoration: underline; /* Underline on hover, if desired */
}

.referral-buttons {
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Add this to make the container take full height */
}

.referral-button {
  /* Add styles for each referral button */
  padding: 10px 10px;
  margin: 0 10px;
  margin-bottom: 10px;
  font-size: 1em;
  background-color: rgba(232, 232, 232, 255);
  /* Set your desired button background color */
  color: rgba(78, 78, 78, 1);
  /* Set button text color */
  border: none;
  border-radius: 5px;
  /* Set button border radius for rounded corners */
  cursor: pointer;
  width: 80%;
}

.referral-code-button {
  /* Add styles for each referral button */
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1em;
  background-color: rgba(232, 232, 232, 255);
  /* Set your desired button background color */
  color: rgba(78, 78, 78, 1);
  /* Set button text color */
  border: none;
  border-radius: 5px;
  /* Set button border radius for rounded corners */
  cursor: pointer;
  width: 20%;
  margin: 0 auto;
  transition: 0.3s;
}

.referral-code-button:hover {
  background-color: #2ecc71;
  color: #fff
}

.referral-button:hover {
  background-color: #2980b9;
  /* Change the color to your preference */
}

.referral-sub-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 80%;
align-items: stretch;
}

.template {
  background-color: #ffffff;
  color: black;
  padding: 15px;
  border-radius: 10px;
  margin: 10px;
  border: 10px solid #f0f0f0;
  margin-bottom: 20px;
  font-family: Helvetica Neue, sans-serif;
  width: 70%;

}
.share {
  background-color: #ffffff;
  color: black;
  padding: 15px;
  border-radius: 10px;
  margin: 10px;
  border: 10px solid #f0f0f0;
  margin-bottom: 20px;
  width: 30%;

}
.referral-stats {
  background-color: #ffffff;
  color: black;
  padding: 15px;
  border-radius: 10px;
  margin: 10px;
  border: 10px solid #f0f0f0;
  margin-bottom: 20px;
  font-family: Helvetica Neue, sans-serif;
  width: 50%;
}

.stats{
  font-size: 2rem;
  margin-top: 10px;
  text-align: center;
  color: rgb(112, 108, 108);
  font-family: "HelveticaNeue-Bold";
}


.str-chat-channel-list {
  height: fit-content;
  margin-bottom: 8px;
  width: 100%;
}

.str-chat-channel {
  height: 100%;
}

.str-chat__load-more-button {
  display: none;
}

.str-chat__input-footer {
  display: none;
}

.str-chat__date-separator {
  margin: 16px 24px;
}

.str-chat__message-notification {
  background: var(--primary-color);
  cursor: pointer;
}

/*
  @media screen and (max-width: 960px) {
    .str-chat-channel-list.team {
      position: unset;
      left: unset;
      top: unset;
      z-index: unset;
      min-height: unset;
      overflow-y: unset;
      box-shadow: unset;
      transition: unset;
    }
  }
  */

.channel-empty__container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.channel-empty__avatars {
  display: flex;
}

.channel-empty__avatars div:first-child {
  z-index: 3;
}

.channel-empty__avatars div:nth-child(2) {
  position: relative;
  right: 32px;
  z-index: 2;
}

.channel-empty__avatars div:nth-child(3) {
  position: relative;
  right: 64px;
  z-index: 1;
}

.channel-empty__avatars .str-chat__avatar {
  margin-right: 0;
}

.channel-empty__first {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  color: #2c2c30;
  margin-bottom: 10px;
}

.channel-empty__first .channel-empty__user-name {
  color: var(--primary-color);
}

.channel-empty__second {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 120%;
  margin: 0;
  color: #858688;
}

.channel__container {
  height: 100%;
  width: 100%;
}

.str-chat__thread {
  z-index: 1;
}

.str-chat__thread-list .str-chat__message-team {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.str-chat__thread-list .str-chat__message-actions-list button:first-child {
  display: none;
}

.str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0px;
}

.str-chat__thread-list .channel-empty__container {
  display: none;
}

.str-chat__date-separator {
  padding: 20px 40px;
}

.custom-thread-header {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.custom-thread-header__left {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.custom-thread-header__left-title {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-right: 10px;
}

.custom-thread-header__left-count {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #858688;
}

.close-thread-icon {
  cursor: pointer;
  margin-right: 10px;
}

.str-chat__list--thread .str-chat__message-simple__actions__action--options .str-chat__message-actions-box {
  left: initial;
  right: 100%;
  border-radius: var(--border-radius-md);
}

.channel-list__container {
  display: flex;
  flex-direction: column;
  /* height: 800px; */
  height: 100vh;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);
}

.banner-wrapper {
  order: -1;
  /* Places the banner at the start of the flex container */
}

.app {
  width: 100%;
}

.channel-list__sidebar__icon1 {
  width: 44px;
  height: 44px;
  margin: 14px;
  background: linear-gradient(150.64deg,
      rgba(0, 0, 0, 0.1) 12.73%,
      rgba(0, 0, 0, 0) 89.32%),
    #ffffff;
  border-radius: 9999px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.33);
}

.channel-list__list__wrapper {
  display: flex;
  flex-direction: row;
  background: white;
  border: 1px solid rgba(232, 232, 232, 1);
  opacity: 1;
  height: 100%
}

.icon1__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-list__sidebar__icon2 {
  width: 44px;
  height: 44px;
  margin: 14px;
  background: linear-gradient(150.64deg,
      rgba(0, 0, 0, 0.1) 12.73%,
      rgba(0, 0, 0, 0) 89.32%),
    #ffffff;
  border-radius: 9999px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.33);
  cursor: pointer;
}

.icon2__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-list__header {
  padding-left: 16px;
  height: 62px;
}

.channel-list__header__text {
  opacity: 1;
  color: rgba(166, 166, 166, 1);
  font-family: "HelveticaNeue-Bold";
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.channel-search__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.channel-search__input__wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 40px;
  background: rgba(232, 232, 232, 255);
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid transparent;
  width: 95%;
}

.channel-search__input__wrapper:focus-within {
  border: 1px solid #fff;
}

.channel-search__input__icon {
  width: 0px;
  display: flex;
  justify-content: center;
}

.channel-search__input__text {
  background: none;
  border: none;
  color: black;
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  outline: none;
}

::placeholder {
  color: rgba(169, 169, 169, 255);
  white-space: nowrap;
  width: 150px;
}

.channel-search__results {
  position: absolute;
  height: fit-content;
  width: 300px;
  background: #fff;
  border: 1px solid #e9e9ea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 10;
  left: 230px;
  top: 16px;
}

.channel-search__results-header {
  width: fit-content;
  display: flex;
  align-items: center;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #858688;
  margin-left: 12px;
}

.channel-search__results-header i {
  font-weight: normal;
  margin-left: 12px;
}

.channel-search__result-container {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
}

.channel-search__result-container__focused {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: var(--primary-color-alpha);
}

.channel-search__result-container:hover {
  background: var(--primary-color-alpha);
  cursor: pointer;
}

.channel-search__result-user {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.result-hashtag {
  height: 24px;
  width: 28px;
  background: var(--primary-color);
  border-radius: 24px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
}

.channel-search__result-text {
  width: 100%;
  font-family: Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #2c2c30;
}

.create-channel__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.create-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding-right: 20px;
}

.create-channel__header p {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-left: 20px;
}

.create-channel__header svg {
  cursor: pointer;
}

.channel-name-input__wrapper {
  display: flex;
  flex-direction: column;
  height: 169px;
  padding-left: 20px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.channel-name-input__wrapper p {
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 120%;
  color: #2c2c30;
  margin-top: 30px;
}

.channel-name-input__wrapper input {
  font-family: Helvetica Neue, sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  height: 50px;
  width: 540px;
  background: #f7f6f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
}

.channel-name-input__wrapper input:focus {
  border: 1px solid var(--primary-color);
  outline: none;
}

.channel-name-input__wrapper input::placeholder {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}

.create-channel__button-wrapper {
  height: 82px;
  background: #f7f6f8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: 16px;

  padding: 0px 10px;
}

.create-channel__button-wrapper p {
  background: var(--primary-color);
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}

.user-list__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-list__header {
  display: flex;
  align-items: center;
  margin: 0px 20px;
  justify-content: space-between;
}

.user-list__header p {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #858688;
  margin-top: 16px;
}

.user-list__header p:first-child {
  flex: 2;
}

.user-list__header p:nth-child(2) {
  flex: 0.5;
  text-align: right;
  margin: 0px 20px;
}

.user-list__message {
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  color: #2c2c30;
  margin: 20px;
}

.user-item__wrapper {
  display: flex;
  align-items: center;
  margin: 0px 20px;
  justify-content: space-between;
}

.user-item__wrapper:hover {
  background: #f7f6f8;
  cursor: pointer;
}

.user-item__name-wrapper {
  display: flex;
  align-items: center;
  flex: 2;
  text-align: left;
}

.user-item__wrapper p {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #2c2c30;
  word-break: break-all;
}

.user-item__name {
  font-weight: 500;
}

.user-item__last-active {
  font-weight: 400;
  width: 30%;
  flex: 0.5;
  text-align: right;

  margin: 0px 20px;
}

.user-item__invite-empty {
  height: 28px;
  width: 28px;
  background: #f7f6f8;
  border: 1px solid #dedddf;
  border-radius: 14px;
  box-sizing: border-box;
  margin-left: 2px;
}

.edit-channel__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.edit-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding-right: 20px;
}

.edit-channel__header p {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-left: 20px;
}

.edit-channel__header svg {
  cursor: pointer;
}

.channel-name-input__wrapper {
  display: flex;
  flex-direction: column;
  height: 169px;
  padding-left: 20px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.channel-name-input__wrapper p {
  font-family: Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 120%;
  color: #2c2c30;
  margin-top: 30px;
}

.channel-name-input__wrapper input {
  font-family: Helvetica Neue, sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0);
  height: 50px;
  width: 90%;
  background: #f7f6f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
}

.channel-name-input__wrapper input:focus {
  border: 1px solid var(--primary-color);
  outline: none;
}

.channel-name-input__wrapper input::placeholder {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}

.edit-channel__button-wrapper {
  height: 82px;
  background: #f7f6f8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: 16px;
}

.edit-channel__button-wrapper p {
  background: var(--primary-color);
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  color: #ffffff;
  margin-right: 30px;
  border-radius: 8px;
  cursor: pointer;
}

.team-channel-header__container {
  position: relative;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 16px;
  z-index: 1;
}

.team-channel-header__channel-wrapper {
  display: flex;
  align-items: center;
}

.team-channel-header__channel-wrapper svg {
  cursor: pointer;
}

.team-channel-header__name {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #2c2c30;
  margin-right: 8px;
}

.team-channel-header__name-wrapper {
  flex: 3;
  display: flex;
  align-items: center;
  overflow-x: auto;
  max-width: 500px;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/*
  @media screen and (min-width: 1070px) {
    .team-channel-header__name-wrapper {
      max-width: 700px;
    }
  }
  */

.team-channel-header__name-wrapper::-webkit-scrollbar {
  display: none;
}

.team-channel-header__name-multi {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.phone {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  top: 30px; /* Adjusts to place the phone number directly below the full name */
  left: 20;  /* Aligns the phone number with the left edge of the full name */
  background-color: rgba(232, 232, 232, 1); /* Optional: background color for better visibility */
  padding: 5px; /* Optional: padding for better spacing */
  border: 1px solid #ccc; /* Optional: border for better visibility */
  z-index: 1; /* Ensures the phone number appears above other elements */
  font-size: 15px;
  border-radius: 10px;
}

.created {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  top: 65px; /* Adjusts to place the phone number directly below the full name */
  left: 20;  /* Aligns the phone number with the left edge of the full name */
  background-color: rgba(232, 232, 232, 1); /* Optional: background color for better visibility */
  padding: 5px; /* Optional: padding for better spacing */
  border: 1px solid #ccc; /* Optional: border for better visibility */
  z-index: 1; /* Ensures the phone number appears above other elements */
  font-size: 15px;
  border-radius: 10px;
}

.last-message-at {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  top: 100px; /* Adjusts to place the phone number directly below the full name */
  left: 20;  /* Aligns the phone number with the left edge of the full name */
  background-color: rgba(232, 232, 232, 1); /* Optional: background color for better visibility */
  padding: 5px; /* Optional: padding for better spacing */
  border: 1px solid #ccc; /* Optional: border for better visibility */
  z-index: 1; /* Ensures the phone number appears above other elements */
  font-size: 15px;
  border-radius: 10px;
}

.days-as-customer {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  position: absolute;
  top: 135px; /* Adjusts to place the phone number directly below the full name */
  left: 20;  /* Aligns the phone number with the left edge of the full name */
  background-color: rgba(232, 232, 232, 1); /* Optional: background color for better visibility */
  padding: 5px; /* Optional: padding for better spacing */
  border: 1px solid #ccc; /* Optional: border for better visibility */
  z-index: 1; /* Ensures the phone number appears above other elements */
  font-size: 15px;
  border-radius: 10px;
}

.label {
  font-weight: normal;
}

.data {
  font-weight: bold;
}

.user-info:hover .phone {
  visibility: visible;
  opacity: 1;
}

.user-info:hover .created {
  visibility: visible;
  opacity: 1;
}

.user-info:hover .last-message-at {
  visibility: visible;
  opacity: 1;
}

.user-info:hover .days-as-customer {
  visibility: visible;
  opacity: 1;
}

.team-channel-header__name-multi:nth-child(3) {
  margin-right: 0px;
}

.team-channel-header__name-wrapper .str-chat__avatar {
  margin-right: 8px;
}

.team-channel-header__name.user {
  font-weight: 500;
  font-size: 14px;
}

.team-channel-header__right {
  flex: 0.55;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.team-channel-header__right-pin-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.team-channel-header__right svg {
  margin-left: 16px;
  margin-right: 4px;
}

.team-channel-header__right-text {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #858688;
}

.team-channel-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}


.team-channel-list__message {
  color: #ffffff;
  padding: 0 16px;
}

.team-channel-list__message.loading {
  height: 115px;
}

.team-channel-list__header {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-channel-list__header svg {
  cursor: pointer;
}

.channel-preview__wrapper {
  opacity: 1;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  opacity: 1;
  height: 80px;
}

.sent-by-me {
  /* Styles for your messages */
  border-radius: 8px;
}

.sent-by-other {
  /* Styles for messages from other users */
  background-color: rgba(155, 202, 236, 0.2);
  /* Change this to the desired background color */
  border-radius: 8px;
}

.channel-preview__wrapper__selected {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(169, 219, 242, 1);
  margin-left: 10px;
  margin-right: 10px;
  height: 80px;
}

.channel-preview__wrapper:hover {
  background: rgba(37, 150, 190, 0.2);
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}


.channel-preview__item.multi {
  max-width: 220px;
}

.channel-preview__item.single .str-chat__avatar {
  margin-right: 12px;
}

.channel-preview__item.multi .str-chat__avatar {
  margin-right: 0;
}

.channel-preview__item.multi span:first-child {
  position: relative;
  z-index: 2;
  bottom: 6px;
}

.channel-preview__item.multi div:nth-child(2) {
  position: relative;
  right: 10px;
  z-index: 1;
  margin-bottom: 0px;
}

.channel-preview__item.multi p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.str-chat__message-team:hover {
  background: #e9e9ea;
}

.str-chat__message-team-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.str-chat__message-team-content p {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #000000;
  margin: 0;
  background: none;
}

.str-chat__message-team:hover .str-chat__message-team-content {
  background: none;
}

.str-chat__message-team-meta {
  justify-content: flex-start;
  align-items: center;
}

.str-chat__message-team--bottom time {
  display: none;
}

.str-chat__message-team--middle time {
  display: none;
}

.str-chat__message-team-meta time {
  visibility: visible;
  z-index: 2;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #858688;
  margin-top: 4px;
}

.str-chat__message-team-author strong {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
  color: #858688;
  margin: 0;
  margin-right: 8px;
}

.str-chat__message-team-content--text {
  border-left: none;
}

.str-chat__message-team--received {
  padding-left: 20px;
}

.str-chat__message-replies-count-button {
  z-index: 2;
  display: flex;
  /* justify-content: flex-end; */
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-color);
}

.str-chat__message-replies-count-button:focus {
  outline: none;
}

.str-chat__message-team-actions {
  right: -3px;
}

.str-chat__date-separator-date {
  color: #858688;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.str-chat__avatar-image img.str-chat__avatar-image--loaded {
  display: none;
}

.str-chat__message-attachment {
  margin-bottom: 0;
}

.str-chat__message-attachment--image {
  max-width: 375px;
}

.str-chat__message-attachment-card--title {
  color: var(--primary-color);
}

.str-chat__message-team .str-chat__message-attachment-card {
  border-radius: 16px;
}

.str-chat__message-team .str-chat__message-attachment-card--content {
  background: #fff;
}

ul.str-chat__simple-reactions-list {
  display: flex;
  align-items: center;
  width: fit-content;
  background: #ffffff;
  border: 1px solid #e9e9ea;
  box-sizing: border-box;
  border-radius: 50vw;
  padding: 6px 6px 4px 6px;
  z-index: 3;
}

ul.str-chat__simple-reactions-list:hover {
  border: 1px solid var(--primary-color);
}

.str-chat__simple-reactions-list-item span {
  display: flex;
  align-items: center;
}

.str-chat__message-team-content ul {
  margin-top: 4px;
}

.emoji-mart-emoji-custom span {
  height: 15px !important;
  width: 15px !important;
}

.str-chat__simple-reactions-list-item--last-number {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #2c2c30;
}

.str-chat__message-attachment-actions-form {
  margin: 0px;
  padding: 10px 0;
  border-top: none;
  border-radius: 16px;
}

.str-chat__message-attachment-actions-button--primary {
  background: var(--primary-color);
  cursor: pointer;
}

.str-chat__message-attachment-actions-button--default {
  background: #fff;
  cursor: pointer;
}

.str-chat__message-attachment-actions-button:focus {
  box-shadow: none;
  border: 2px solid rgba(0, 0, 0, 0.08);
}

.str-chat__message-attachment-actions-button:hover {
  font-weight: 700;
  border: 1px solid var(--primary-color);
}

.str-chat__message-team-status {
  display: none;
}

.str-chat__message-actions-list button:hover {
  color: var(--primary-color);
}

.emoji-mart-anchor-selected {
  color: var(--primary-color) !important;
}

.emoji-mart-anchor-bar {
  background: var(--primary-color) !important;
}

.str-chat__message-team--editing {
  background: #e9e9ea;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.06),
    inset 0 1px 0 0 var(--primary-color), inset 0 -1px 0 0 var(--primary-color);
  padding: 15px 20px;
}

.str-chat__message-team--editing .str-chat__message-team-form-footer {
  padding: 10px 0 0;
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button[type="submit"] {
  color: var(--primary-color);
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.33);
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
}

.str-chat__edit-message-form .str-chat__message-team-form-footer button:focus {
  outline: none;
}

.str-chat__edit-message-form textarea {
  box-shadow: 0 0 0 1px var(--primary-color);
}

.str-chat__edit-message-form textarea:focus {
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.str-chat__edit-message-form svg {
  display: none;
}

.str-chat__message-team-content p a {
  color: var(--primary-color);
}

.pinned-message {
  background: #e6efff;
}

.unpinned-message {
  background: #fff;
}

.str-chat__message-team-pin-indicator {
  display: flex;
  align-items: center;
  margin-left: 40px;
  height: 18px;
}

.str-chat__message-team-pin-indicator div svg {
  fill: #858688;
  font-size: 10px !important;
}

.str-chat__message-team-pin-indicator div div {
  font-family: Helvetica Neue, sans-serif;
  font-size: 10px !important;
  line-height: 120%;
  color: #858688;
  margin-left: 4px;
}

.str-chat__reaction-selector {
  background: #ffffff;
  border: 1px solid #e9e9ea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 99999px;
}

.str-chat__reaction-selector li {
  font-size: 24px;
}

.str-chat__reaction-selector li span span {
  height: 24px !important;
  width: 24px !important;
}

.str-chat__message-team-content--single:not(.str-chat__message-team-content--image)::before {
  display: none;
}

.team-message-input__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f7f6f8;
  padding: 15px 20px 28px;
  width: 100%;
}

.team-message-input__wrapper.thread-open {
  border-bottom-right-radius: 0px;
}

.team-message-input__input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.team-message-input__input:focus-within {
  border-color: var(--primary-color);
}

.team-message-input__top {
  min-height: 43px;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.team-message-input__wrapper .giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  background: var(--primary-color);
  border-radius: 12px;
  margin-left: 8px;
}

.team-message-input__wrapper .giphy-icon__text {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;
}

.team-message-input__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 0, 0.05);
}

.team-message-input__button {
  position: relative;
  width: 0;
  right: 32px;
  cursor: pointer;
}

.team-message-input__input:focus-within .team-message-input__button path {
  fill: var(--primary-color);
  fill-opacity: 1;
}

.team-message-input__wrapper .str-chat__textarea textarea {
  display: flex;
  background: #fff;
  border: none;
  outline: none;
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.team-message-input__wrapper .str-chat__textarea textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background: #fff;
}

.team-message-input__wrapper .str-chat__textarea textarea::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.team-message-input__icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.team-message-input__icons div:first-child {
  margin: 0 5px;
}

.team-message-input__icons div:nth-child(3) {
  margin-left: 5px;
}

.team-message-input__icons svg {
  width: 36px;
}

.team-message-input__icons svg:hover path {
  fill: var(--primary-color);
  fill-opacity: 1;
}

.team-message-input__icons svg:hover {
  cursor: pointer;
}

.icon-divider {
  width: 1px;
  height: 40px;
  background: rgba(204, 204, 204, 0.5);
}

.team-message-input__wrapper .str-chat__input--emojipicker {
  z-index: 3;
  position: absolute;
  bottom: 112px;
  left: 20px;
  width: 338px;
}

.emoji-mart .emoji-mart-emoji:focus {
  outline: none;
}

div.rfu-dropzone:focus {
  outline: none;
}

.rfu-image-previewer {
  flex: none;
  margin-left: 12px;
}

.rfu-image-previewer__image {
  margin-bottom: 0;
}

div.rta__autocomplete.str-chat__emojisearch {
  z-index: 10;
  position: relative;
  width: 100%;
  background: #fff;
  margin: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rta__entity--selected {
  background: var(--primary-color);
}

.str-chat__slash-command:hover {
  background: var(--primary-color);
  cursor: pointer;
}

.rta__list-header {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c30;
  mix-blend-mode: normal;
  opacity: 0.9;
}

.str-chat__thread {
  background: #fff;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.str-chat__thread-header {
  height: 62px !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.str-chat__thread-header-details {
  display: flex;
  height: 62px !important;
  align-items: center;
}

.str-chat__thread-header-details strong {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-right: 10px;
}

.str-chat__thread-header-details small {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #858688;
}

.str-chat__thread .str-chat__square-button {
  border-radius: 20px;
  height: 35px;
  width: 35px;
}

.str-chat__square-button svg {
  fill: var(--primary-color);
}

.str-chat__square-button:hover {
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.str-chat__square-button:focus {
  outline: none;
}

.str-chat__thread-start {
  padding: 0;
  margin: 20px 0 0 0;
  font-size: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.str-chat .str-chat__list--thread {
  padding: 0px;
}

.str-chat .str-chat__list--thread .str-chat__reverse-infinite-scroll {
  padding-top: 0px;
}

.str-chat__thread-list {
  top: 62px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
}

.str-chat__thread-list .str-chat__message-team {
  padding: 10px;
}

.thread-message-input__wrapper {
  display: flex;
  align-items: center;
  padding: 15px 20px 28px;
  width: 100%;
}

.thread-message-input__wrapper .giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  background: var(--primary-color);
  border-radius: 12px;
  margin-left: 8px;
}

.thread-message-input__wrapper .giphy-icon__text {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;
}

.thread-message-input__input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
}

.thread-message-input__input:focus-within {
  border-color: var(--primary-color);
}

.thread-message-input__button {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 44px;
  width: 41px;
}

.thread-message-input__input:focus-within .thread-message-input__button path {
  fill: var(--primary-color);
  fill-opacity: 1;
}

.thread-message-input__wrapper .str-chat__textarea textarea {
  display: flex;
  align-items: center;
  background: #fff;
  border: none;
  outline: none;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 10px;
}

.thread-message-input__wrapper .str-chat__textarea textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background: #fff;
}

.thread-message-input__wrapper .str-chat__textarea textarea::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.thread-message-input__icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  padding-right: 6px;
}

.thread-message-input__icons svg:hover path {
  fill: var(--primary-color);
  fill-opacity: 1;
}

.thread-message-input__icons svg:hover {
  cursor: pointer;
}

.thread-message-input__wrapper .str-chat__input--emojipicker {
  z-index: 3;
  top: 183px;
  right: 299px;
  width: 338px;
}

.emoji-mart .emoji-mart-emoji:focus {
  outline: none;
}

.team-channerl-header_menu-icon {
  display: none;
}

.channerl-list__container-toggle {
  display: none;
  height: 50px;
  width: 50px;
  background: #005fff;
  position: absolute;
  right: -2%;
  top: 50%;
  border-radius: 50%;
  z-index: 2;
}

.channel-list__container-responsive {
  display: none;
  height: 100%;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 90%;
  top: 0%;
  z-index: 5;
  transition: 0.8s ease;
}

.str-chat__input-flat {
  width: 100%;
  padding: 10px;
}

/*
  @media screen and (max-width: 960px) {
    .channel-list__container {
      display: none;
    }
  
    .team-channerl-header_menu-icon {
      display: flex;
    }
  
    .team-channel-header__container {
      height: 70px;
    }
  
    .team-channel-header__name-wrapper {
      height: 100%;
      max-width: unset;
    }
  
    .team-channel-header__name-multi {
      margin-right: 8px;
      align-items: center;
      flex-direction: column;
    }
  
    .team-channel-header__name.user {
      font-size: 10px;
      margin: 5px;
      text-align: center;
    }
  
    .team-channel-header__right {
      display: flex;
      padding-left: 10px;
    }
  
    .channel-list__container-responsive {
      display: flex;
    }

  
    .channerl-list__container-toggle {
      display: flex;
    }
  
    .channel-search__input__wrapper {
      width: 90%;
      padding-left: 10px;
      justify-content: flex-start;
    }
  
    .channel-search__input__text {
      width: inherit;
    }
  
    .channel-preview__item.multi {
      width: 80%;
    }
  
    .channel-search__results {
      top: 100%;
      left: 5%;
    }
  }
  */

/*
  @media screen and (max-width: 650px) {
    .channerl-list__container-toggle {
      right: -3%;
    }
  }
  */

/*
  @media screen and (max-width: 400px) {
    .channel-preview__item.multi {
      width: 150px;
    }
  
    .channerl-list__container-toggle {
      right: -5%;
    }
  }
  */

/*
  @media screen and (max-width: 320px) {
    .channel-list__sidebar {
      display: none;
    }
  }
  */